$(document).ready(function(){
  $("#register-guest-btn").attr('disabled', true);
  $("input.guest-terms").change(function(){
    var agree_input = $(this);
    var next_btn    = $("#register-guest-btn");

    if(agree_input.prop("checked")){
      next_btn.prop("disabled", false);
    }else{
      next_btn.prop("disabled", true);
    }
  })

  $("#register-guest-btn").click(function(){
    if ($('#abo-number').val().length >= 1) {
      aboNumberValidation();
    }

    emailValidation(true);
  });

  if($("#co-applicant-no").is(':checked')){
    $("#form-co-applicant").hide();
  }else if($("#co-applicant-yes").is(':checked')){
    $("#form-co-applicant").show();
  }

  $('#submit-update-guest').click(function() {
    var validation = stepThreeValidation();
    var membership_package = $('#input-membership-package-id').val()
    var payment_check      = $('#payment_check').val()
    var use_balance        = $('#use_balance').val()

    if (validation === true){
      $(this).prop('disabled', true);

      var update_guest_form = $("form#update-account-guest");
      var fields            = '';

      $("input[name^=user]").add($("select[name^=user]")).each(function(){
        if (($(this).prop("type") == "radio") && ($(this).prop("checked") == false)){ return; }
        fields += '<input type="hidden" value="' + $(this).val() + '" name="' + $(this).prop("name") + '">';
      })

      if (membership_package != 0) {
        fields += `<input type="hidden" name="input-membership-package-id" id="input-membership-package-id" value="${membership_package}">`;
      }

      if (payment_check != 0) {
        fields += `<input type="hidden" name="payment_check" id="payment_check" value="${payment_check}">`;
      }

      if ($('#abo-number').val().length < 1) {
        fields += '<input type="hidden" value="false" name="user[account_attributes][skip_abo_number_validation]">';
        fields += '<input type="hidden" value="false" name="user[account_attributes][skip_abo_number_required]">';
      }

      update_guest_form.append(fields);
      update_guest_form.submit();
      return false;
    }
  })

  function stepThreeValidation(){
    var form = $("#step-three");
    form.validate({
      rules: {
        'user[account_attributes][abo_number]': { required: true },
        'user[account_attributes][platinum_recognition_name]': { required: true },
        // 'user[account_attributes][platinum_abo_number]': { required: true },
        'user[account_attributes][sponsor_abo_number]': { required: true },
        'user[active_system_upline_abo_number]': { required: true },
        'platinum_abo_number': { required: true },
        // 'active_system_upline_abo_number': { required: true },
        'active_system_upline_name': { required: true },
        'temp_platinum_name': { required: true }
      }
    });
    return form.valid();
  }

  function emailValidation(validate_sign_up = false){
    var email = $('#email').val();
    var user_id    = $('#user-obj-id').val();
    var partner_id = $('#partner-obj-id').val();

    if (user_id == undefined) {
      user_id = null;
    }

    if (partner_id == undefined) {
      partner_id = null;
    }

    $.ajax({
      type: "GET",
      dataType:"json",
      data: { 'email' : email, 'user_id' : user_id, 'partner_id' : partner_id },
      url: "/api/services/email_validation",
      error: function (xhr, ajaxOptions, thrownError) {
        emailError();
      },
      success: function(response){
        if (response['email']['valid']) {
          $(".email-sign-up-error").text("");
          timeout = setTimeout(() => {
            signUpFormValidation();
          }, 100);
        } else {
          emailError(response['email']['message']);
        }
      }
    });
  }

  function aboNumberValidation(){
    var abo_number = $('#abo-number').val();
    var id         = $('#account-obj-id').val();
    if (id == undefined) {
      id = null;
    }

    $.ajax({
      type: "GET",
      dataType:"json",
      data: {'abo_number' : abo_number, 'id': id},
      url: "/api/services/abo_number_validation",
      error: function (xhr, ajaxOptions, thrownError) {
        aboNumberError();
      },
      success: function(response){
        if (response['result'] && response['valid_length'] == false) {
          $("#abo-number-error").text("");
        } else if (response['result'] == false) {
          aboNumberError(response);
        } else if (response['valid_length'] == true) {
          aboNumberError(response);
        }
      }
    });
  }

  function emailError(message = I18n.t('global.alerts.email_already_been_taken')){
    $(".email-sign-up-error").attr('style', '');
    $(".email-sign-up-error").text('')
    $(".email-sign-up-error").append(message);
    return false;
  }

  function aboNumberError(response){
    var abo_number = $('#abo-number').val();
    $("#abo-number-error").attr('style', '');
    if ($("#abo-number-error").text().length < 1) {
      if (response['result'] == false) {
        $("#abo-number-error").append(I18n.t('global.alerts.abo_number_exist'));
      } else if (response['valid_length'] == true) {
        $("#abo-number-error").append(I18n.t('global.alerts.abo_number_lengh_validation'));
      }
    }
    return false;
  }

  function signUpFormValidation(){
    var form = $("#sign-up-form");

    var checked_error_abo_number = $('#abo-number-error').text().length >= 1
    var error_messages           = $('#abo-number-error').text()

    console.log(checked_error_abo_number, error_messages)
    $.validator.addMethod('aboNumberValidate', function (value, element, param) {
      return !checked_error_abo_number;
    }, error_messages);

    form.validate({
      rules: {
        'user[email]': { required: true, email: true },
        'confirm_email': { equalTo: '#email' },
        'profile[name]': { required: true },
        'profile[guest_phone_number_code]': { required: true },
        'profile[guest_phone_number]': { required: true },
        'user[password]': { required: true, minlength: 6 },
        'user[password_confirmation]': { equalTo: '#password' },
        'required-agreement': { required: true },
        'user[account_attributes][abo_number]': {
          aboNumberValidate: true
        }
      },
      messages: {
        'name': I18n.t('global.alerts.please_specify_your_full_name'),
        'password': I18n.t('global.alerts.password_validate'),
        'password_confirmation': I18n.t('global.alerts.please_enter_same_password_as_above'),
      },
      errorPlacement: function (error,element) {
        if(element.attr("name") == "required-agreement") {
          element.data( "toggle", "tooltip" );
          element.data( "placement", "top" );
          element.prop( "title", error.text() );
          element.tooltip('show');
        } else {
          error.insertAfter(element);
        }
      }
    });
    if (form.valid() === true){
      form.submit();
    }
  }
});