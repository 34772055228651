$(document).ready(function() {
  $('#mode_pase_center').change(function() {
    var val_mode = $(this).val();

    if (val_mode == 'hybrid' || val_mode == 'virtual') {
      $('#external_link').prop('required', true);
      $('#span_external_link').removeClass('d-none');
    } else {
      $('#external_link').prop('required', false);
      $('#span_external_link').addClass('d-none');
    }
  });

  // START FILTER PASE ATTENDANCES
  var currentYear = moment().year();

  var currentYearStart = moment({
    years: currentYear,
    months: '0',
    date: '1'
  });

  var currentYearEnd = moment({
    years: currentYear,
    months: '11',
    date: '31'
  });

  var start = moment();

  // BEGINNING OF MONTH START FILTER
  if ($('#meeting-filter-date').length > 0 && $('#meeting-filter-date').data('start') == "beginning-of-month") {
    start = moment().clone().startOf('date');
  }

  var end = moment().add(1, 'year');

  function cb(start, end) {
    $('#meeting-filter-date').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMM D, YYYY'));
  }

  var dateRange = {};
  dateRange[I18n.t('global.today_and_upcoming')] = [moment(), moment().add(1, 'year')];
  dateRange[I18n.t('global.last_num_days', { num: 7 })] = [moment().subtract(6, 'days'), moment()];
  dateRange[I18n.t('global.last_num_days', { num: 14 })] = [moment().subtract(13, 'days'), moment()];
  dateRange[I18n.t('global.last_num_days', { num: 30 })] = [moment().subtract(29, 'days'), moment()];

  $('#meeting-filter-date').daterangepicker({
    startDate: start,
    endDate: end,
    ranges: dateRange,
    locale: {
      format: 'DD/MM/YYYY'
    }
  }, cb);

  cb(start, end);

  $('#meeting-filter-date').on('apply.daterangepicker', function(ev, picker) {
    var buttonId = $(ev.currentTarget).data('submit-button');
    $(buttonId).click();
  });

  $('.ranges li').click(function() {
    var dateLabel = $(this).html();
    $('.attendances-label-selected').html(dateLabel);
  })

  $("#meeting-filter-date").change(function(){
    $("#submit-filter-daterange").click();
  });
  // END FILTER PASE ATTENDANCES
});