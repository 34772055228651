// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("material-design-iconic-font/dist/css/material-design-iconic-font.min.css")

import "bootstrap"
import "../stylesheets/application"
import "../stylesheets/application_user"

var jQuery = require('jquery')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

var moment = require('moment')
global.moment = moment;
window.moment = moment;

import I18n from 'i18n-js';

import "./app.js"
import "./app.init.horizontal.js"
import "./app-style-switcher.js"

import "./vendor/perfect-scrollbar/perfect-scrollbar.jquery.min.js"
import "./vendor/sparkline/sparkline.js"
import "./vendor/datatables/dataTables.bootstrap4.min.js"
import "./vendor/datatables/dataTables.responsive.min.js"
import "./vendor/jquery-ui-datepicker/jquery-ui-datepicker.js"
import "./vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js"
import "./vendor/daterangepicker/daterangepicker.js"
import "./vendor/summernote/summernote-bs4.min.js"
import "pc-bootstrap4-datetimepicker"
import "./vendor/validate/jquery.validate.min.js"
import "./vendor/analytic/js/metisMenu.js"
import "./vendor/analytic/js/dashboard_init.js"
import "./vendor/analytic/js/custom.js"
import "./vendor/select2/select2.min.js"
import "./vendor/slick/slick.min.js"
import "./vendor/paging/paging.min.js"

// https://stevepolito.design/blog/create-a-nested-form-in-rails-from-scratch/
import "./nested-forms/addFields.js"
import "./nested-forms/removeFields.js"

import "./waves.js"
import "./sidebarmenu.js"
import "./sign_up.js"
import "./custom.js"
import "./dashboard.js"
import "./user.js"
import "./subscription.js"
import "./event.js"
import "./general-image.js"
import "./cart.js"
import "./pricing-tier.js"
import "./resource.js"
import "./slotmachine.js"
import "./slotmachine.min.js"
import "./abo_number_validation.js"
import "./lucky_draw.js"
import "./event-organization.js"
import "./platinum_downlines.js"
import "./achievement-submission.js"
import "./success_profile.js"
import "./image-list.js"
import "./generate-qrcode.js"
import "./major-function-children.js"
import "./incentive_trip.js"
import "./validate_date_range.js"
import "chartkick/chart.js"
import "./whole-team-membership.js"
import "./super_core_qualifications.js"
import "./balance-groups.js"
import "./event-list-calendar.js"
import "./initSortable.js"
import "./custom_user.js"
import "./ewallet-balance.js"
import "./pase-center.js"
import "./pase-center-scanqr.js"
import "./bulk-pase-attendances.js"
import "./person_in_charges.js"
import "./wizard_connecting_star.js"
import "./guest-sign-up.js"
import "./ad-hoc-lucky-draw-registration.js"
import "./reload-page.js"
import "./edit-guest.js"
import "./user.js"
import "./recommended-book-user.js"
import "./ticket-and-meal-transfer.js"

// file upload to aws
import fileUpload from './fileUpload.js'
import fileMultiUpload from './fileMultiUpload.js'
import fileUploadCropper from './fileUploadCropper.js'
import fileUploadDashboard from './fileUploadDashboard.js'

// file upload to local
import fileUploadLocal from './fileUploadLocal.js'
import fileMultiUploadLocal from './fileMultiUploadLocal.js'
import fileUploadCropperLocal from './fileUploadCropperLocal.js'
import fileUploadDashboardLocal from './fileUploadDashboardLocal.js'

// listen on 'turbolinks:load' instead of 'DOMContentLoaded' if using Turbolinks
document.addEventListener('DOMContentLoaded', () => {
  var datepicker = $.fn.datepicker.noConflict();
  $.fn.bootstrapDP = datepicker;

  I18n.locale = $('body').data('locale');
  I18n.translations = $('body').data('translations');
  global.I18n = I18n;

  var rails_env = document.querySelector('div[data-env]').dataset.env
  document.querySelectorAll('.upload-file').forEach(fileInput => {
    if (rails_env == 'development') {
      fileUploadLocal(fileInput)
    } else {
      fileUpload(fileInput)
    }
  })

  document.querySelectorAll('.upload-multi-file').forEach(fileInput => {
    if (rails_env == 'development') {
      fileMultiUploadLocal(fileInput)
    } else {
      fileMultiUpload(fileInput)
    }
  })

  // FOR UPLOAD CROPPER IMAGE
  document.querySelectorAll('.upload-file-cropper').forEach(fileInput => {
    if (rails_env == 'development') {
      fileUploadCropperLocal(fileInput)
    } else {
      fileUploadCropper(fileInput)
    }
  })

  document.querySelectorAll('.upload-uppy-dashbord').forEach(fileInput => {
    if (rails_env == 'development') {
      fileUploadDashboardLocal(fileInput)
    } else {
      fileUploadDashboard(fileInput)
    }
  })

  if (!(I18n.locale == 'en')) {
    var day_names        = I18n.t('date.day_names');
    var abbr_day_names   = I18n.t('date.abbr_day_names');
    var days_min         = I18n.t('date.days_min');
    var abbr_month_names = I18n.t('date.abbr_month_names');
    var month_names      = I18n.t('date.month_names');

    moment.updateLocale(I18n.locale, {
      monthsShort: [  abbr_month_names[1],
                      abbr_month_names[2],
                      abbr_month_names[3],
                      abbr_month_names[4],
                      abbr_month_names[5],
                      abbr_month_names[6],
                      abbr_month_names[7],
                      abbr_month_names[8],
                      abbr_month_names[9],
                      abbr_month_names[10],
                      abbr_month_names[11],
                      abbr_month_names[12]
                    ],
      monthNames: [ month_names[1],
                    month_names[2],
                    month_names[3],
                    month_names[4],
                    month_names[5],
                    month_names[6],
                    month_names[7],
                    month_names[8],
                    month_names[9],
                    month_names[10],
                    month_names[11],
                    month_names[12]
                  ],
      dayNamesMin: [ days_min[0],
                      days_min[1],
                      days_min[2],
                      days_min[3],
                      days_min[4],
                      days_min[5],
                      days_min[6]
                    ]
    });

    $.fn.bootstrapDP.dates[I18n.locale] = {
        days: [ day_names[0], day_names[1], day_names[2], day_names[3], day_names[4], day_names[5], day_names[6] ],
        daysShort: [ abbr_day_names[0], abbr_day_names[1], abbr_day_names[2], abbr_day_names[3], abbr_day_names[4], abbr_day_names[5], abbr_day_names[6] ],
        daysMin: [  days_min[0], days_min[1], days_min[2], days_min[3], days_min[4], days_min[5], days_min[6] ],
        months: [  month_names[1], month_names[2], month_names[3],
                   month_names[4], month_names[5], month_names[6],
                   month_names[7], month_names[8], month_names[9],
                   month_names[10], month_names[11], month_names[12] ],
        monthsShort: [ abbr_month_names[1], abbr_month_names[2], abbr_month_names[3],
                       abbr_month_names[4], abbr_month_names[5], abbr_month_names[6],
                       abbr_month_names[7], abbr_month_names[8], abbr_month_names[9],
                       abbr_month_names[10], abbr_month_names[11], abbr_month_names[12] ]
    };
  }

})
